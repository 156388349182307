import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { connectToGame } from './services/websocket';
import LoadingScreen from './components/LoadingScreen';
import InvitePlayersModal from './components/InvitePlayersModal';

const GamePage = () => {
  const { game_id } = useParams();
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { status, totalPlayers } = useSelector(state => state.game);
  const gameInviteLink = `https://yourgameurl.com/invite/${game_id}`;

  useEffect(() => {
    connectToGame(game_id, dispatch);

    const loadingTimeout = setTimeout(() => {
      setShowLoading(false);
    }, 15000);

    return () => clearTimeout(loadingTimeout);
  }, [game_id, dispatch]);

  useEffect(() => {
    // Open the modal if status is 'waiting' and totalPlayers is 0
    if (status === 'waiting' && totalPlayers === 0) {
      setIsModalOpen(true);
    }
  }, [status, totalPlayers]);

  if (showLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="h-screen w-full flex justify-center items-center relative" style={{ backgroundColor: '#91d31a' }}>
      {/* Render InvitePlayersModal */}
      <InvitePlayersModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        gameInviteLink={gameInviteLink} 
      />
    </div>
  );
};

export default GamePage;
