import React from 'react';
import { FaCopy } from 'react-icons/fa';
import { HiMail, HiOutlineShare, HiOutlineX } from 'react-icons/hi';

const InvitePlayersModal = ({ isOpen, onClose, gameInviteLink }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(gameInviteLink);
    alert('Game invite link copied!');
  };

  const shareOptions = [
    {
      name: 'Email',
      icon: <HiMail className="inline-block mr-1" />,
      link: `mailto:?subject=Join my poker game!&body=Click the link to join: ${gameInviteLink}`,
    },
    {
      name: 'WhatsApp',
      icon: <HiOutlineShare className="inline-block mr-1" />,
      link: `https://wa.me/?text=Join my poker game! ${gameInviteLink}`,
    },
    {
      name: 'Telegram',
      icon: <HiOutlineShare className="inline-block mr-1" />,
      link: `https://telegram.me/share/url?url=${gameInviteLink}&text=Join my poker game!`,
    },
    {
      name: 'Facebook',
      icon: <HiOutlineShare className="inline-block mr-1" />,
      link: `https://www.facebook.com/sharer/sharer.php?u=${gameInviteLink}`,
    },
    {
      name: 'Twitter',
      icon: <HiOutlineShare className="inline-block mr-1" />,
      link: `https://twitter.com/intent/tweet?url=${gameInviteLink}&text=Join my poker game!`,
    },
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg transform transition-all duration-500 scale-110">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold text-green-700">Invite Players</h3>
          <button onClick={onClose} className="text-red-500 hover:text-red-700">
            <HiOutlineX className="w-6 h-6" />
          </button>
        </div>
        <p className="mb-6 text-gray-700">Share the game invite link with your friends via:</p>

        {/* Share Buttons */}
        <div className="flex flex-wrap gap-4 justify-center mb-6">
          {shareOptions.map((option) => (
            <a
              key={option.name}
              href={option.link}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center bg-green-500 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-green-600 transition duration-300"
            >
              {option.icon} Share via {option.name}
            </a>
          ))}
        </div>

        {/* Copy Link Button */}
        <button
          onClick={copyToClipboard}
          className="flex items-center justify-center bg-blue-500 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-600 transition duration-300 w-full"
        >
          <FaCopy className="mr-2" /> Copy Link
        </button>
      </div>
    </div>
  );
};

export default InvitePlayersModal;
